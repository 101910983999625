import React from "react";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Pagination, Drawer, TextField, Chip } from "@mui/material";
import { NavBarPainel } from "../../components/painel/NavBarPainel";
import { InfoPainel } from "../../components/painel/InfoPainel";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from "react-toastify";
import { DrawerCurso } from "../../components/painel/DrawerCurso";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerModulosExterno } from "../../components/painel/DrawerModulosExterno";
import useFetchGetPainelMatriculas from "../../queries/painel/matriculas";
import { DrawerMatricula } from "../../components/painel/DrawerMatricula";
import usePlataforma from "../../hooks/usePlataforma";



export const MatriculasAluno = () => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate();
  const { apagarMatricula } = usePlataforma()

  const location = useLocation();
    const { state } = location;

  const handleChange = (event, value) => {
    setPage(value);
  }
  
  const handleDrawer = () => {
    setOpen(true);
  }

  const handleApagarMatricula = (id) => {
    apagarMatricula(id);
    toast.info("Registro apagado com sucesso.")
  }

  const { isLoading, data } = useFetchGetPainelMatriculas(page, state._id)

  return (
    <Box>
      <NavBarPainel />
      <InfoPainel paginaAnterior="Voltar" paginaAtual="Matrículas" />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 8 }}
      >
        <Button onClick={handleDrawer} variant="contained" startIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Stack>
      <Box sx={{ mt: 2, px: 8 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cursos matriculados</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.docs.map(current => (
                <TableRow key={current._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {current.cursoID.nome}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                <Button onClick={() => handleApagarMatricula(current._id)} size="small" variant="outlined" color='error' >Desmatricular</Button>
                </Stack>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <DrawerMatricula open={open} setOpen={setOpen} userID={state._id} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};
