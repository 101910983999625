import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import { Title } from "../Title";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "react-query";

const drawerWidth = 300;

export const DrawerCurso = ({ open, setOpen, dataEdit, setDataEdit }) => {
  const queryClient = useQueryClient();
  const [image, setImage] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [idModulo, setIdModulo] = useState(null);

  const formik = useFormik({
    initialValues: {
      isPublished: "",
      nome: "",
      ordem: "",
      linkCompra: "",
      product_cod: "",
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Esse campo é obrigatório!'),
      isPublished: Yup.string().required('Esse campo é obrigatório!'),
      ordem: Yup.string().required('Esse campo é obrigatório!'),
      linkCompra: Yup.string().required('Esse campo é obrigatório!'),
      product_cod: Yup.string().required('Esse campo é obrigatório!'),
    }),
    onSubmit: async (values) => {
      const id = toast.loading("Carregando...");

      const data = new FormData();
      data.append("nome", values.nome);
      data.append("product_cod", values.product_cod);
      data.append("ordem", values.ordem);
      data.append("linkCompra", values.linkCompra);
      data.append("isPublished", values.isPublished);
      data.append("temImagem", Boolean(image));
      data.append("imagem", image);

      const options = {
        method: "POST",
        url: isNew ? "/add/curso" : `/edit/curso/${idModulo}`,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data,
      };

      api.request(options)
        .then(function (response) {
          toast.update(id, {
            render: "Registro salvo com sucesso.",
            type: "success",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
          queryClient.invalidateQueries(['painelcursos'])
          closeDrawer()
        })
        .catch(function (error) {
          toast.update(id, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
        });
    },
  });

  function closeDrawer() {
    limpaInpultFiles()
    setDataEdit(null)
    setIdModulo(null)
    setIsNew(true)
    formik.resetForm();
    setOpen(false)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    if (dataEdit) {
      setIsNew(false)
      setIdModulo(dataEdit._id)
      formik.setValues({
        isPublished: dataEdit.isPublished,
        nome: dataEdit.nome,
        ordem: dataEdit.ordem,
        linkCompra: dataEdit.linkCompra,
        product_cod: dataEdit.product_cod,
      });
    }
  }, [dataEdit]);
  
  const inputImagem = useRef(null);

  const limpaInpultFiles = () => {
    setImage(null);
    if (inputImagem.current) {
      inputImagem.current.value = ""
    }
  };

    return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{height: 73, bgcolor: "#323238", mb: 3}}>
          <Box sx={{mt: 3, ml: 3}}>
          <Title>{isNew ? "Adicionar Curso" : "Editar Curso"}</Title>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
        <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        sx={{px: 2}}
      >
        <Box>
        <Box sx={{mb: 0.5}}><Typography variant="caption">Imagem</Typography></Box>
        <TextField
              variant="outlined"
              fullWidth
              type="file"
              name="file"
              inputRef={inputImagem}
              onChange={(event) => handleImageChange(event)}
            /></Box>
        <Input formik={formik} id="ordem" label="Ordem" type="number" />
        <Input formik={formik} id="nome" label="Nome" />
        <Input formik={formik} id="product_cod" label="Código do produto" />
        <Input formik={formik} id="linkCompra" label="Link Compra" />
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Escolha uma opção</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formik.values.isPublished}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label="Escolha uma opção"
          name="isPublished"
        >
          <MenuItem value={true}>Publicar</MenuItem>
          <MenuItem value={false}>Lançamento</MenuItem>
        </Select>
      </FormControl>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
        sx={{ my: 2, px: 2}}
      >
        <Button type="submit" disabled={formik.isSubmitting} fullWidth size="small" variant="contained" >{isNew ? "Salvar" : "Editar"}</Button>
        <Button onClick={closeDrawer} fullWidth size="small" variant="outlined" color='warning' >Cancelar</Button>
      </Stack>
      </form>
      </Drawer>
  );
}
