import { useQuery } from "react-query";
import api from "../../service/api";

async function getAulas(ctx) {
  const [, cursoID, moduloID] = ctx.queryKey
  const { data } = await api.get('/aulas', { params: { cursoID, moduloID } })
  return data
}

export default function useFetchGetAulas(cursoID, moduloID) {
    return useQuery(['aulas', cursoID, moduloID], getAulas, { refetchOnWindowFocus: false})
}