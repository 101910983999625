import React from 'react';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import useFetchGetSlides from '../queries/slides';
import config from '../constants';

export const Slide = () => {

  const { data } = useFetchGetSlides()

  return (
    <Swiper
    
        navigation
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        style={{ width: '100%', height: '100%', "--swiper-pagination-color": "#fff",
        "--swiper-navigation-color": "#fff", }}
        loop
        autoplay={{
          delay: 12000,
          disableOnInteraction: false,
        }}
      >
        {data && data.data.map(current => (
          <SwiperSlide key={current._id}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundImage:
                'linear-gradient(0deg, rgb(9 9 10) 10%, rgb(9 9 10 / 88%) 30%, rgba(9, 9, 10, 0) 80%, rgba(9, 9, 10, 0) 90%, rgba(9, 9, 10, 0) 100%)',
            }}
          />
          <img
            src={current.imagem}
            style={{ width: '100%', height: '100%' }}
          />
        </SwiperSlide>
        ))}
      </Swiper>
  );
}
