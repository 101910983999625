import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import { Modulo } from "../pages/Modulo";
import { Aulas } from "../pages/Aulas";
import { Login } from "../pages/Login";
import useStore from "../providers/useStore";
import { Favoritos } from "../pages/Favoritos";
import { Comunidade } from "../pages/Comunidade";
import { useJwt } from "react-jwt";
import { useEffect } from "react";
import { Perfil } from "../pages/Perfil";
import { AulasFavoritas } from "../pages/AulasFavoritas";
import { Resetpassword } from "../pages/Resetpassword";
import { SolicitarSenha } from "../pages/SolicitarSenha";
import { CursosPainel } from "../pages/painel/Cursos";
import { LoginPainel } from "../pages/painel/Login";
import { ModulosPainel } from "../pages/painel/Modulos";
import { AulasPainel } from "../pages/painel/Aulas";
import { AlunosPainel } from "../pages/painel/Alunos";
import { ModulosExternosPainel } from "../pages/painel/ModulosExternos";
import { MatriculasAluno } from "../pages/painel/MatriculasAluno";
import { Slides } from "../pages/painel/Slides";
import { Materiais } from "../pages/painel/Materiais";

export function Rotas() {
  const token = useStore((state) => state.token);
  const tokenAdmin = useStore((state) => state.tokenAdmin);
  const setUser = useStore((state) => state.setUser);
  const { decodedToken } = useJwt(token);

  useEffect(() => {
    if (decodedToken) {
      setUser(decodedToken);
    }
  }, [decodedToken, setUser]);

  const renderRoutes = (routes) => {
    return (
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    );
  };

  const privateRoutes = [
    { path: "/", element: <Home /> },
    { path: "/login", element: <Home redirect={true}/> },
    { path: "/modulos", element: <Modulo /> },
    { path: "/aulas", element: <Aulas /> },
    { path: "/aulas/favoritas", element: <AulasFavoritas /> },
    { path: "/favoritos", element: <Favoritos /> },
    { path: "/comunidade", element: <Comunidade /> },
    { path: "/perfil", element: <Perfil /> },
  ];

  const adminRoutes = [
    { path: "/painel", element: <CursosPainel /> },
    { path: "/painel/modulos", element: <ModulosPainel /> },
    { path: "/painel/modulosexternos", element: <ModulosExternosPainel /> },
    { path: "/painel/aulas", element: <AulasPainel /> },
    { path: "/painel/alunos", element: <AlunosPainel /> },
    { path: "/painel/slides", element: <Slides /> },
    { path: "/painel/materiais", element: <Materiais /> },
    { path: "/painel/aluno/cursos", element: <MatriculasAluno /> },
  ];

  const publicRoutes = [
    { path: "/", element: <Login redirect={false} /> },
    { path: "/resetpassword", element: <Resetpassword /> },
    { path: "/recuperar-senha", element: <SolicitarSenha /> },
    { path: "/painel", element: <LoginPainel/> },
    { path: "*", element: <Login redirect={true} /> },
  ];

  return (
    <BrowserRouter>
      {token && renderRoutes(privateRoutes)}
      {tokenAdmin && renderRoutes(adminRoutes)}
      {!token && !tokenAdmin && renderRoutes(publicRoutes)}
    </BrowserRouter>
  );
}
