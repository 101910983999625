import React from "react";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Pagination, Drawer, TextField, Chip } from "@mui/material";
import { NavBarPainel } from "../../components/painel/NavBarPainel";
import { InfoPainel } from "../../components/painel/InfoPainel";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer } from "react-toastify";
import { DrawerCurso } from "../../components/painel/DrawerCurso";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchGetPainelAulas from "../../queries/painel/aulas";
import { DrawerAulas } from "../../components/painel/DrawerAula";
import UploadIcon from '@mui/icons-material/Upload';
import SwapVertIcon from '@mui/icons-material/SwapVert';

export const AulasPainel = () => {
  const [dataEdit, setDataEdit] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate();

  const location = useLocation();
    const { state } = location;
  const handleChange = (event, value) => {
    setPage(value);
  }
  
  const handleDrawer = () => {
    setOpen(true);
  }

  const { isLoading, data } = useFetchGetPainelAulas(page, state._id)

  const OpenLinkInNewTab = (link) => {
    window.open(link, '_blank');
    };

    function handleMaterial(data) {
      navigate("/painel/materiais", { state: data });
    }

    function handleEdit(current) {
      setDataEdit(current)
      setOpen(true);
    }

  return (
    <Box>
      <NavBarPainel />
      <InfoPainel paginaAnterior="Voltar" paginaAtual={`Aulas - ${state.nome}`} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 8 }}
      >
        <Button onClick={handleDrawer} variant="contained" startIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Stack>
      <Box sx={{ mt: 2, px: 8 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Ordem</TableCell>
                <TableCell >link Imagem</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data.docs.map(current => (
                <TableRow key={current._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {current.nome}
                </TableCell>
                <TableCell ><Chip size="small" icon={<SwapVertIcon />} label={current.ordem} /></TableCell>
                <TableCell ><Button onClick={() => OpenLinkInNewTab(current.imagem)} size="small" variant="text" >VER LINK</Button></TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Button startIcon={<UploadIcon />} onClick={() => handleMaterial(current)} size="small" variant="contained" color='info' >Materiais </Button>
                    <Button onClick={() => handleEdit(current)} size="small" variant="outlined" color='warning' >Editar</Button>
                  </Stack>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <DrawerAulas open={open} setOpen={setOpen} cursoID={state.cursoID} moduloID={state._id} setDataEdit={setDataEdit} dataEdit={dataEdit}/>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};
