import { useQuery } from "react-query";
import api from "../../service/api";

async function getMaterial(ctx) {
  const [, aulaID] = ctx.queryKey
  const { data } = await api.get('/material', { params: { aulaID } })
  return data
}

export default function useFetchGetMaterial(aulaID) {
    return useQuery(['material', aulaID], getMaterial, { refetchOnWindowFocus: false})
}