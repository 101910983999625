import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelCursos(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/painel/cursos', { params: { page } })
  return data
}

export default function useFetchGetPainelCursos(page) {
    return useQuery(['painelcursos', page], getPainelCursos, { refetchOnWindowFocus: false})
}