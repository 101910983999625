import React from "react";
import { Breadcrumbs, IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from "react-router-dom";

export const InfoPainel = ({paginaAtual, paginaAnterior}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    let navigate = useNavigate();

  function handleVoltar() {
    navigate(-1);
  }

    return (
        <React.Fragment>
            {isMobile ?
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                    width="100%"
                    height="64px"
                    sx={{ borderBottom: 1, borderColor: "#323238", px: 2 }}
                >
                    <IconButton onClick={handleVoltar} color="inherit" aria-label="Voltar" component="label">
                        <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h6">{paginaAtual}</Typography>
                </Stack> : <Stack
                    width="100%"
                    height="64px"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    sx={{ borderBottom: 1, borderColor: "#323238", px: '48px' }}
                >
                    <Typography variant="h6">{paginaAtual}</Typography>
                    <Breadcrumbs separator={<NavigateNextIcon sx={{ color: "#B8B8BF" }} fontSize="small" />} aria-label="breadcrumb">
                        <Typography onClick={handleVoltar} sx={{
                            cursor: "pointer", '&:hover': {
                                color: '#fff',
                                textDecoration: 'underline',
                            }
                        }} color="text.disabled">{paginaAnterior}</Typography>
                        <Typography color="text.primary">{paginaAtual}</Typography>
                    </Breadcrumbs>
                </Stack>}
        </React.Fragment>
    );
}
