import { useState } from "react";
import { Box, Button, Grid, TextField } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import { Avatar, Typography, Paper } from '@mui/material';
import useStore from "../providers/useStore";
import api from "../service/api";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import config from "../constants";
import usePlataforma from "../hooks/usePlataforma";

export const Perfil = () => {
    const user = useStore((state) => state.user);
    const setUserImage = useStore((state) => state.setUserImage);
    const avatarUser = user.imagem ? user.imagem : null
    const [name, setName] = useState(user.nome);
    const [newAvatar, setNewAvatar] = useState(null);
    const [image, setImage] = useState(null);
    const { resetpassword } = usePlataforma()
    
    
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImage(file)
            setNewAvatar(URL.createObjectURL(file));
        }
    };

    const handleSave = () => {
        const id = toast.loading("Carregando...");
        const data = new FormData();
        data.append("nome", name);
        data.append("imagem", image);
        data.append("temImagem", Boolean(image));

        const options = {
            method: "POST",
            url: "/user/foto",
            headers: {
                'Content-Type': 'multipart/form-data; boundary=---011000010111000001101001',
            },
            data
        };

        api
            .request(options)
            .then(function (response) {
                if(response.data.fotoAlterada){
                    setUserImage(response.data.imagem)
                }
                toast.update(id, {
                    render: "O Perfil foi alterado com sucesso.",
                    type: "success",
                    isLoading: false,
                    autoClose: true,
                    closeButton: true,
                });
            })
            .catch(function (error) {
                toast.update(id, {
                    render: error.response.data.message,
                    type: "error",
                    isLoading: false,
                    autoClose: true,
                    closeButton: true,
                });
            });
    };

    const handleAvatarButtonClick = () => {
        document.getElementById('avatar-upload').click();
    };

    function alterarSenha() {
        toast.info("Você recebeu um e-mail de redefinição de senha.")
        resetpassword(user.email)
    }

    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual="Perfil" paginaAnterior="DTR Academy" />
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                    <Paper sx={{ padding: 4 }}>
                        <Grid container justifyContent="center" alignItems="center" direction="column" spacing={2}>
                            <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Avatar sx={{ width: 100, height: 100, marginBottom: 2 }} src={newAvatar || avatarUser} alt={user.name} />
                                <Button component="span" variant="contained" color="primary" onClick={handleAvatarButtonClick}>
                                    Alterar Foto
                                </Button>
                                <input type="file" accept="image/*" onChange={handleAvatarChange} id="avatar-upload" style={{ display: 'none' }} />
                            </Grid>
                            <Grid item>
                                <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                    Perfil de Usuário
                                </Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    label="Nome"
                                    variant="outlined"
                                    value={name}
                                    onChange={handleNameChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" align="center" sx={{ marginBottom: 2 }}>
                                    <strong>Email:</strong> {user.email}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={handleSave} fullWidth>
                                    Salvar Alterações
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="warning" variant="text" onClick={alterarSenha} fullWidth>
                                    Alterar senha
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Box sx={{mb: 3}} />
            <ToastContainer />
        </Box>
    );
}
