
import * as React from 'react';
import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../service/api';
import { Link, useNavigate } from 'react-router-dom';
import useStore from '../providers/useStore';

export const SolicitarSenha = ({}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Deve ser um e-mail válido').required('E-mail é obrigatório'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      const id = toast.loading("Carregando...");
      try {
        const { data } = await api.post("/resetpassword", values);
        setIsLoading(false)
        toast.update(id, {
          render: "Você recebeu um e-mail de redefinição de senha.",
          type: "success",
          isLoading: false,
          autoClose: true,
          closeButton: true,
        });
      } catch (error) {
        setIsLoading(false)
        toast.update(id, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: true,
          closeButton: true,
        });
      }
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      backgroundImage: 'url(https://caiotapereading.com.br/static/backgroundImage.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <Box sx={{ textAlign: "center", maxWidth: 140 }}>
              <img
                alt="logo"
                src="/logo.png"
                style={{
                  display: "inline-block",
                  width: '100%',
                }}
              />
            </Box>
      <Paper elevation={2} sx={{mt: 2, px:5, py: 5, width: {xs: "90%", sm: "90%", md: 440, lg: 440, xl: 440}, borderRadius: 5 }} >
        <Box >
      <Typography color="text.primary" sx={{fontSize: 24, fontWeight: 600}}>
      Esqueceu sua senha?
      </Typography>
      <Typography color="text.secondary"  sx={{fontSize: 14, fontWeight: 400}}>
      Enviaremos um e-mail com instruções de como redefinir sua senha.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
      <Box mt={3}>
      <Typography color="text.primary"  sx={{fontSize: 14, fontWeight: 600}}>
      Email
      </Typography>
      <TextField
      name="email"
      value={formik.values.email}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      size="small"
      sx={{mt: 0.5}}
      fullWidth
      variant="outlined"
      error={Boolean(formik.touched.email && formik.errors.email)}
      helperText={formik.touched.email && formik.errors.email}
      />
      </Box>
      <Box mt={3}>
      <Button disabled={isLoading} type="submit" size="medium" fullWidth variant="contained">Enviar</Button>
      </Box>
      </form>
        </Box>
      </Paper>
      <ToastContainer />
    </Box>
  );
}
