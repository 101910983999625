import create from 'zustand'

const useStore = create((set) => ({
  token: null,
  tokenAdmin: null,
  user: null,
  setToken: (token) => set({ token }),
  setTokenAdmin: (tokenAdmin) => set({ tokenAdmin }),
  setUser: (user) => set({ user }),
  setUserImage: (imagem) =>
    set((state) => ({
      user: {
        ...state.user,
        imagem: imagem
      }
    }))

}))

export default useStore