import { Avatar, Backdrop, Box, Card, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import VideoPlayer from "../components/VideoPlayer";
import { AdjustScreen } from "../components/AdjustScreen";
import { ListPlayList } from "../components/ListPlayList";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import TabDescricao from "../components/TabDescricao";
import { useLocation } from "react-router-dom";
import useFetchGetAulas from "../queries/aulas";
import { useEffect, useState } from "react";
import config from "../constants";
import { ButtonAssistida } from "../components/ButtonAssistida";
import { ButtonFavorita } from "../components/ButtonFavorita";

export const Aulas = () => {
    const [curso, setCurso] = useState(null);
    const location = useLocation();
    const { state } = location;
    const { data, isLoading } = useFetchGetAulas(state.cursoID._id, state._id)

    useEffect(() => {
        if (!isLoading) {
          const arrayAulas = data.data;
          const aulasNaoAssistidas = arrayAulas.filter(aula => !aula.assistida);
          const curso = aulasNaoAssistidas.length > 0 ? aulasNaoAssistidas[0] : arrayAulas[arrayAulas.length - 1];
          setCurso(curso);
        }
      }, [isLoading]);

    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual={state.nome} paginaAnterior={state.cursoID.nome} />
            <AdjustScreen>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}  >
                      {curso && <VideoPlayer aulaID={curso._id} src={curso.video} />}  
                        <Typography sx={{mt: 1, fontSize: "1.3rem", color: "#E1E1E6", fontWeight: 700}} color="initial">{curso ? curso.nome : ""}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Card sx={{ bgcolor: "#19191C", minHeight: 364 }} >
                            <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%', bgcolor: "#323238", maxHeight: 48 }}
                            >
                                <Box sx={{py: 1}}>
                            <Typography sx={{fontSize: "1.1rem", color: "#E1E1E6", fontWeight: 700}} color="initial">Playlist</Typography>
                                </Box>
                            </Stack>
                            <PerfectScrollbar style={{ maxHeight: 311 }}>
                            <List>
                                {curso && data && data.data.map(current => (
                                <ListPlayList
                                curso={current}
                                setCurso={setCurso}
                                key={current._id}
                                imagem={current.imagem}
                                nomeAula={current.nome}
                                selected={current._id === curso._id}
                                />
                                ))}
                            </List>
                            </PerfectScrollbar>
                        </Card>
                        <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        sx={{mt: 1}}
                        >
                            <ButtonAssistida aulaID={curso ? curso._id : ""} marked={curso ? curso.assistida : false} nome="Assistido"/>
                            <ButtonFavorita aulaID={curso ? curso._id : ""} marked={curso ? curso.favorito : false} nome="Favorito"/>
                        </Stack>
                    </Grid>
                </Grid>
            </AdjustScreen>
            <Box sx={{bgcolor: "rgb(18, 18, 20)", mt: 7}}>
                <AdjustScreen>
                    <Box sx={{pb: 3}}/>
                    <TabDescricao descricao={curso ? curso.descricao : ""} aulaID={curso ? curso._id : ""}/>
                </AdjustScreen>
                <Box sx={{pb: 4}}/>
            </Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Box>
    );
}
