import { TextField } from "@mui/material";

export const Input = ({ label, id, formik, type }) => {
  return (
      <TextField
        label={label}
        name={id}
        value={formik.values[id]}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        fullWidth
        variant="outlined"
        error={Boolean(formik.touched[id] && formik.errors[id])}
        helperText={formik.touched[id] && formik.errors[id]}
        type={type ? type : "text"}
      />
  );
};
