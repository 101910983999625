import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelCursosMatricula() {
  const { data } = await api.get('/painel/cursosmatricula')
  return data
}

export default function useFetchGetPainelCursosMatricula() {
    return useQuery(['painelcursosmatricula'], getPainelCursosMatricula, { refetchOnWindowFocus: false})
}