import { Card, CardMedia, Stack, IconButton, Button, Box, Typography } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

export const CardModuloInicio = ({ imagem, bloqueado, modulo }) => {
  let navigate = useNavigate();

  function handleModulo() {
    if(bloqueado){
      toast.info("Faça um Upgrade para Lv.3 Premium!")
    } else {
      navigate("/aulas", { state: modulo });
    }
  }

  return (
    <Box sx={{p:1}}>
    <Card
    onClick={handleModulo}
      sx={{
        border: 1, borderColor: "#323238",
        mr: 2,
        transition: "all .2s ease-in-out",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.03)",
          "& .overlay": {
            display: "flex", // Mostra o overlay (Stack) ao passar o mouse
          },
        },
      }}
    >
      <div style={{ position: "relative" }}>
        {bloqueado && (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            className="overlay" // Adiciona uma classe ao Stack para estilização
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              zIndex: 1,
              display: "none", // Inicia oculto
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton color="inherit">
              <LockIcon />
            </IconButton>
            <Typography align="center" sx={{
            fontSize: "1.3rem",
            color: "#e1e1e6",
            margin: "0px",
            fontWeight: 400
          }}>
          Faça um<br/>Upgrade
          </Typography>
          </Stack>
        )}

        <CardMedia
          sx={{
            height: 380,
            filter: bloqueado ? "grayscale(100%)" : "none", // Aplica a escala de cinza se estiver bloqueado
          }}
          image={imagem}
          // title={nome}
        />
      </div>
    </Card>
    
    </Box>
  );
};
