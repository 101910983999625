import { useQuery } from "react-query";
import api from "../../service/api";

async function getModulosexterno() {
  const { data } = await api.get('/modulosexterno')
  return data
}

export default function useFetchGetModulosexterno() {
    return useQuery(['modulosexterno'], getModulosexterno, { refetchOnWindowFocus: false})
}