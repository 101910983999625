import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import { Title } from "../Title";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect, useRef, useState } from "react";
import { Input } from "./Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "react-query";
import useFetchGetPainelModulosExterno from "../../queries/painel/modulosExternos";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const drawerWidth = 300;

export const DrawerModulos = ({ open, setOpen, cursoID, dataEdit, setDataEdit }) => {
  const queryClient = useQueryClient();
  const [image, setImage] = useState(null);
  const [isNew, setIsNew] = useState(true);
  const [idModulo, setIdModulo] = useState(null);

  const formik = useFormik({
    initialValues: {
      nome: "",
      moduloexterno: "",
      ordem: "",
      descricao: "",
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Esse campo é obrigatório!'),
      moduloexterno: Yup.string().required('Esse campo é obrigatório!'),
      ordem: Yup.string().required('Esse campo é obrigatório!'),
      descricao: Yup.string().required('Esse campo é obrigatório!'),
    }),
    onSubmit: async (values) => {
      const id = toast.loading("Carregando...");

      const data = new FormData();
      data.append("nome", values.nome);
      data.append("ordem", values.ordem);
      data.append("moduloexterno", values.moduloexterno);
      data.append("descricao", values.descricao);
      data.append("cursoID", cursoID);
      data.append("temImagem", Boolean(image));
      data.append("imagem", image);

      const options = {
        method: "POST",
        url: isNew ? "/add/modulo" : `/edit/modulo/${idModulo}`,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data,
      };

      api.request(options)
        .then(function (response) {
          toast.update(id, {
            render: "Registro salvo com sucesso.",
            type: "success",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
          queryClient.invalidateQueries(['painelmodulos'])
          closeDrawer()
        })
        .catch(function (error) {
          toast.update(id, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
        });
    },
  });

  useEffect(() => {
    if (dataEdit) {
      setIsNew(false)
      setIdModulo(dataEdit._id)
      formik.setValues({
        nome: dataEdit.nome,
        moduloexterno: dataEdit.moduloexterno._id,
        ordem: dataEdit.ordem,
        descricao: dataEdit.descricao,
      });
    }
  }, [dataEdit]);

  function closeDrawer() {
    setIdModulo(null)
    setIsNew(true)
    formik.resetForm();
    setOpen(false)
    setDataEdit(null)
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const { data } = useFetchGetPainelModulosExterno()

 async function handleDelete() {
    await api.post(`/apagar/modulo/${idModulo}`)
    toast.info("🚀 O registro foi para o espaço.")
    queryClient.invalidateQueries(['painelmodulos'])
    closeDrawer()
  }

  const inputImagem = useRef(null);

  const limpaInpultFiles = () => {
    setImage(null);
    if (inputImagem.current) {
      inputImagem.current.value = ""
    }
  };

    return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{height: 73, bgcolor: "#323238", mb: 3}}>
          <Box sx={{mt: 3, ml: 3}}>
          <Title>{isNew ? "Adicionar Módulo" : "Editar Módulo"}</Title>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
        <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        sx={{px: 2}}
      >
        <Box>
        <Box sx={{mb: 0.5}}><Typography variant="caption">Imagem</Typography></Box>
        <TextField
              variant="outlined"
              fullWidth
              type="file"
              name="file"
              inputRef={inputImagem}
              onChange={(event) => handleImageChange(event)}
            /></Box>
        <Input formik={formik} id="ordem" label="Ordem" type="number" />
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Escolha o módulo</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formik.values.moduloexterno}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label="Escolha o módulo"
          name="moduloexterno"
          disabled={data ? false : true}
        >
          
         {data && data.data.docs.map(current => (
           <MenuItem key={current._id} value={current._id}>{current.nome}</MenuItem>
         )
          )}
        </Select>
      </FormControl>
        <Input formik={formik} id="nome" label="Nome" />
        <Input formik={formik} id="descricao" label="Descrição" />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
        sx={{ my: 2, px: 2}}
      >
        <Button type="submit" disabled={formik.isSubmitting} fullWidth size="small" variant="contained" >{isNew ? "Salvar" : "Editar"}</Button>
        <Button onClick={closeDrawer} fullWidth size="small" variant="outlined" color='warning' >Cancelar</Button>
      </Stack>
      </form>
      <Box sx={{ px: 2}}>
       {!isNew && <Button onClick={handleDelete} startIcon={<DeleteForeverIcon/>} fullWidth size="small" variant="contained" color="error" >APAGAR MÓDULO</Button>}
      </Box>
      </Drawer>
  );
}
