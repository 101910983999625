import * as React from 'react';
import { Box, Button, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export const NavBarPainel = () => {
  let navigate = useNavigate();

  function handleLink(link) {
    navigate(link);
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        height: 72,
        background: "#202024",
        borderBottom: 1.5, borderColor: "#323238",
        width: "100%"
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Box sx={{ width: 70, ml: 5 }}>
          <img src="/logo.png"
            alt="logo"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{pr: 3}}
      >
        <Button onClick={() => handleLink("/painel")} >CURSOS</Button>
        <Button onClick={() => handleLink("/painel/modulosexternos")} >MÓDULOS EXTERNOS</Button>
        <Button onClick={() => handleLink("/painel/alunos")} >ALUNOS</Button>
        <Button onClick={() => handleLink("/painel/slides")} >SLIDES</Button>
      </Stack>
    </Stack>
  );
}
