import { useQuery } from "react-query";
import api from "../../service/api";

async function getSlides() {
  const { data } = await api.get('/slides')
  return data
}

export default function useFetchGetSlides() {
    return useQuery(['slides'], getSlides, { refetchOnWindowFocus: false})
}