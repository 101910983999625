import { Backdrop, Box, Button, Card, CardContent, CardHeader, CardMedia, CircularProgress, Grid } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import { CardModulo } from "../components/CardModulo";
import { useLocation } from "react-router-dom";
import useFetchGetModulos from "../queries/modulos";
import config from "../constants";

export const Comunidade = () => {


    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual="Comunidade" paginaAnterior="DTR Academy"/>
            <Box sx={{ mt: 2, px: 2, width: {sx: '90%', sm: "90%", md: 400, lg: 400, xl: 400} }}>
                <Card sx={{cursor: "not-allowed"}}>
                    <CardHeader title="DTR Academy [Comunidade]" subheader="Acesso o que há de melhor para destravar de vez a sua leitura de fluxo!" />
                   <Box sx={{p:1}}>
                    <Button sx={{cursor: "not-allowed"}} color="inherit" fullWidth>Disponível em breve!</Button>
                   </Box>
                </Card>
            </Box>
        </Box>
    );
}
