import { useQuery } from "react-query";
import api from "../../service/api";

async function getModulos(ctx) {
  const [, cursoID] = ctx.queryKey
  const { data } = await api.get('/modulos', { params: { cursoID } })
  return data
}

export default function useFetchGetModulos(cursoID) {
    return useQuery(['modulos', cursoID], getModulos, { refetchOnWindowFocus: false})
}