import { Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import usePlataforma from "../hooks/usePlataforma";
import { useEffect, useState } from "react";

export const ButtonAssistida = ({ nome, marked, aulaID }) => {
  const [ativo, setAtivo] = useState(marked);
  const { marcaAsstida, removerAssistida } = usePlataforma();

  useEffect(() => {
    setAtivo(marked);
  }, [marked]);

  function handleMarked() {
    if (ativo) {
      setAtivo(false);
      removerAssistida(aulaID);
    } else {
      setAtivo(true);
      marcaAsstida(aulaID);
    }
  }

  return (
    <Button
      onClick={handleMarked}
      fullWidth
      variant="contained"
      color={ativo ? 'primary' : 'btn'}
      startIcon={ativo ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    >
      {nome}
    </Button>
  );
};
