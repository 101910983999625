import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import { Title } from "../Title";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect, useState } from "react";
import { Input } from "./Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "react-query";
import useFetchGetPainelModulosExterno from "../../queries/painel/modulosExternos";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import useFetchGetPainelCursosMatricula from "../../queries/painel/cursosparamatricular";

const drawerWidth = 300;

export const DrawerMatricula = ({ open, setOpen, userID }) => {
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      cursoID: "",
    },
    validationSchema: Yup.object({
      cursoID: Yup.string().required('Esse campo é obrigatório!'),
    }),
    onSubmit: async (values) => {
      const id = toast.loading("Carregando...");

      const data = new FormData();
      data.append("userID", userID);
      data.append("cursoID", values.cursoID);

      const options = {
        method: "POST",
        url: "/matricular",
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data,
      };

      api.request(options)
        .then(function (response) {
          toast.update(id, {
            render: "Registro salvo com sucesso.",
            type: "success",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
          queryClient.invalidateQueries(['painelmatriculas'])
          closeDrawer()
        })
        .catch(function (error) {
          toast.update(id, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
        });
    },
  });

  function closeDrawer() {
    formik.resetForm();
    setOpen(false)
  }



  const { data } = useFetchGetPainelCursosMatricula()

    return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{height: 73, bgcolor: "#323238", mb: 3}}>
          <Box sx={{mt: 3, ml: 3}}>
          <Title>Matrícula</Title>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
        <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        sx={{px: 2}}
      >
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Escolha o curso</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={formik.values.cursoID}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          label="Escolha o curso"
          name="cursoID"
          disabled={data ? false : true}
        >
          
         {data && data.data.map(current => (
           <MenuItem key={current._id} value={current._id}>{current.nome}</MenuItem>
         )
          )}
        </Select>
      </FormControl>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
        sx={{ my: 2, px: 2}}
      >
        <Button type="submit" disabled={formik.isSubmitting} fullWidth size="small" variant="contained" >Salvar</Button>
        <Button onClick={closeDrawer} fullWidth size="small" variant="outlined" color='warning' >Cancelar</Button>
      </Stack>
      </form>
      </Drawer>
  );
}
