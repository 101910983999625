import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import usePlataforma from '../hooks/usePlataforma';
import config from '../constants';

const VideoPlayer = ({ src, aulaID }) => {
  const videoRef = useRef(null);
  const { marcaAsstida } = usePlataforma();

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      if (videoElement.player) {
        videoElement.player.dispose();
      }

      videoElement.innerHTML = '<video class="video-js"></video>';

      const videoPlayer = videojs(videoElement.firstChild, { fluid: true }, () => {
        // console.log('Player de vídeo inicializado');
      });

      videoPlayer.src({
        src: `${config.baseURL}/video/${src}`,
        type: 'video/mp4'
      });

      videoPlayer.on('ended', () => {
        marcaAsstida(aulaID);
      });

      videoPlayer.controlBar.pictureInPictureToggle.dispose();
      videoPlayer.controls(true); // Adiciona os controles de reprodução

      return () => {
        if (videoPlayer) {
          videoPlayer.dispose();
        }
      };
    }
  }, [src]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} className="video-container"></div>
    </div>
  );
};

export default VideoPlayer;
