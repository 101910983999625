import { useQuery } from "react-query";
import api from "../../service/api";

async function getLancamentos() {
  const { data } = await api.get('/lancamentos')
  return data
}

export default function useFetchGetLancamentos() {
    return useQuery(['lancamentos'], getLancamentos, { refetchOnWindowFocus: false})
}