import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

export const ListPlayList = ({ nomeAula, imagem, selected, setCurso, curso }) => {

  function handleCurso() {
    setCurso(curso)
  }

  return (
    <ListItemButton
      onClick={handleCurso}
      selected={selected}
      dense={true}
      sx={{
        "&.Mui-selected": {
          backgroundColor: "rgba(183, 186, 185, 0.13)",
          borderBottom: "1px solid #00B37E",
          "&:hover": {
            backgroundColor: "rgba(183, 186, 185, 0.3)"
          }
        },
        "&:hover": {
          backgroundColor: "rgba(183, 186, 185, 0.13)"
        }
      }}
    >
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            color={selected ? "primary" : "inherit"}
          >
            {selected ? (
              <PlayCircleIcon sx={{ fontSize: 30 }} />
            ) : (
              <PlayCircleOutlineIcon sx={{ fontSize: 30 }} />
            )}
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar sx={{ borderRadius: 1 }} alt={nomeAula} src={imagem} />
        </ListItemAvatar>
        <ListItemText primary={nomeAula} />
      </ListItem>
    </ListItemButton>
  );
};
