import { Alert, Backdrop, Box, Card, CardHeader, CardMedia, CircularProgress, Grid } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import { CardModulo } from "../components/CardModulo";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchGetModulos from "../queries/modulos";
import config from "../constants";
import useFetchGetFavoritas from "../queries/favoritas";

export const Favoritos = () => {
    const { data, isLoading } = useFetchGetFavoritas()
    let navigate = useNavigate()

    function handleLick(aula) {
    navigate("/aulas/favoritas", { state: {aulas: data.data, atual: aula}});
  }

    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual="Aulas Favoritas" paginaAnterior="DTR Academy"/>
            <Box sx={{ mt: 2, px: 2 }}>
              {data && data.data.length === 0 && <Box sx={{ width: {sx: '90%', sm: "90%", md: 400, lg: 400, xl: 400}}}>
            <Alert severity="warning">Você ainda não selecionou nenhuma aula favorita.</Alert>
                </Box>}
                <Grid container spacing={2} >
                    {data && data.data.map(current => (
                        <Grid key={current._id} item xs={12} sm={12} md={4} lg={2} xl={2}>
                            <Card 
                            onClick={() => handleLick(current)}
                            sx={{
                                maxWidth: 600,
                                border: 1,
                                borderColor: "#323238",
                                transition: "all .2s ease-in-out",
                                cursor: "pointer",
                                "&:hover": {
                                    transform: "scale(1.03)",
                                },
                            }}
                            >
                                <CardMedia
                                    sx={{ height: 150 }}
                                    image={current.aulaID.imagem}
                                />
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{mb: 5}} />
            </Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Box>
    );
}
