
import { Backdrop, Box, Card, CardMedia, CircularProgress, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { Slide } from "../components/Slide";
import 'react-multi-carousel/lib/styles.css'
import Carousel from 'react-multi-carousel';
import { CardCurso } from "../components/CardCurso";
import { Title } from "../components/Title";
import { CardLancamento } from "../components/CardLancamento";
import { useNavigate } from "react-router-dom";
import React from "react";
import useFetchGetCursos from "../queries/cursos";
import config from "../constants";
import useFetchGetLancamentos from "../queries/lancamentos";
import useFetchGetModulosexterno from "../queries/modulosInicio";
import { CardModuloInicio } from "../components/CardModuloInicio";
import { ToastContainer } from "react-toastify";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


const Home = ({redirect}) => {
  let navigate = useNavigate();

  React.useEffect(() => {
    if(redirect){
      navigate("/", { replace: true });
    }
  }, [redirect, navigate]);

  const { data, isLoading } = useFetchGetCursos()
  const { data: lancamentos } = useFetchGetLancamentos()
  const { data: modulos } = useFetchGetModulosexterno()

  return (
    <Box>
      <NavBar />
      <Box
        sx={{
          width: '100%',
          height: '70vh',
          backgroundColor: '#09090a',
          overflow: 'hidden',
        }}
      >
        <Slide />
      </Box>
      <Box sx={{ mt: 4, px: 7 }}>
        <Title>DTR Academy</Title>
        {data && <Carousel responsive={responsive} swipeable={true} draggable={true}>
          {data.data.map(current => (
            <CardCurso key={current._id} curso={current} nome={current.nome} imagem={current.imagem} bloqueado={!current.comprado} />
          ))}
        </Carousel>}
      </Box>
      <Box sx={{ mt: 6, px: 7 }}>
        <Title>Próximos Lançamentos</Title>
        {lancamentos && <Carousel responsive={responsive} swipeable={true} draggable={true}>
          {lancamentos.data.map(current => (
            <CardLancamento key={current._id} nome={current.nome} imagem={current.imagem}  />
          ))}
        </Carousel> }
      </Box>
      <Box sx={{ mt: 6, mb: 10, px: 7 }}>
        <Title>Módulos</Title>
       {modulos && <Carousel responsive={responsive} swipeable={true} draggable={true}>
          {modulos.data.map(current => (
            <CardModuloInicio bloqueado={!current.comprado} modulo={current} key={current._id} nome={current.nome} imagem={current.imagem}  />
          ))}
        </Carousel> }
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};

export default Home;
