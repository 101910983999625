import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, Grid, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import useFetchGetMaterial from '../queries/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabDescricao({descricao, aulaID}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useFetchGetMaterial(aulaID)

  return (
    <Box sx={{ width: '100%', height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'rgb(41, 41, 46)' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Descrição" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
            {descricao}
          </Grid>
        {data && data.data.length > 0 &&  <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
            <Card sx={{ bgcolor: "#202024", maxHeight: 160, minHeight: 160 }} >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: '100%', background: 'linear-gradient(90deg, rgb(41, 41, 46) 0%, rgba(32, 32, 36, 0) 100%)', maxHeight: 48 }}
              >
                <Box sx={{ py: 1 }}>
                  <Typography sx={{ fontSize: "1.1rem", color: "#E1E1E6", fontWeight: 700 }} color="initial">Material complementar</Typography>
                </Box>
              </Stack>
              <Box sx={{px: 3, py: 1}}>
              <PerfectScrollbar style={{ maxHeight: 90 }}>
                <ul>
                  {data && data.data.map(current => (
                    <li key={current._id}>
                    <a href={current.link} target="_blank" rel="noopener noreferrer" style={{ color: '#C4C4CC', textDecoration: 'underline' }}>
                    {current.nome}
                    </a>
                  </li>
                  ))}
                </ul>
              </PerfectScrollbar>
              </Box>
            </Card>
          </Grid>}
        </Grid>
      </TabPanel>
    </Box>
  );
}