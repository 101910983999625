import React from "react";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Pagination, Drawer, TextField, Chip } from "@mui/material";
import { NavBarPainel } from "../../components/painel/NavBarPainel";
import { InfoPainel } from "../../components/painel/InfoPainel";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from "react-toastify";
import { DrawerCurso } from "../../components/painel/DrawerCurso";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchGetPainelModulosExterno from "../../queries/painel/modulosExternos";
import { DrawerModulosExterno } from "../../components/painel/DrawerModulosExterno";
import { DrawerSlide } from "../../components/painel/DrawerSlide";
import useFetchGetSlides from "../../queries/slides";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import api from "../../service/api";
import { useQueryClient } from "react-query";
import useFetchGetMaterial from "../../queries/material";
import { DrawerMaterial } from "../../components/painel/DrawerMaterial";


export const Materiais = () => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
    const { state } = location;
    console.log({state})

  const handleChange = (event, value) => {
    setPage(value);
  }
  
  const handleDrawer = () => {
    setOpen(true);
  }

  const OpenLinkInNewTab = (link) => {
    window.open(link, '_blank');
    };

  const { isLoading, data } = useFetchGetMaterial(state._id)

  async function deleteMaterial(id) {
    await api.post(`/apagar/material/${id}`)
    toast.info("🚀 O material foi para o espaço.")
    queryClient.invalidateQueries(['material'])
  }

  return (
    <Box>
      <NavBarPainel />
      <InfoPainel paginaAnterior="Voltar" paginaAtual={`Materiais: ${state.nome}`} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 8 }}
      >
        <Button onClick={handleDrawer} variant="contained" startIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Stack>
      <Box sx={{ mt: 2, px: 8 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Link do material</TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data.map(current => (
                <TableRow key={current._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {current.nome}
                </TableCell>
                <TableCell ><Button endIcon={<OpenInNewIcon />} onClick={() => OpenLinkInNewTab(current.link)} size="small" variant="text" >VER LINK</Button></TableCell>
                <TableCell align="right" component="th" scope="row">
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                <Button onClick={() => deleteMaterial(current._id)} size="small" variant="outlined" color='error' >Apagar</Button>
                </Stack>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <DrawerMaterial open={open} setOpen={setOpen} aulaID={state._id}/>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};
