import { Backdrop, Box, Card, CardHeader, CardMedia, CircularProgress, Grid } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import { CardModulo } from "../components/CardModulo";
import { useLocation } from "react-router-dom";
import useFetchGetModulos from "../queries/modulos";
import config from "../constants";

export const Modulo = () => {
    const location = useLocation();
    const { state } = location;

    const { data, isLoading } = useFetchGetModulos(state._id)

    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual={state.nome} paginaAnterior="DTR Academy"/>
            <Box sx={{ mt: 2, px: 2 }}>
                <Grid container spacing={2} >
                    {data && data.data.map(current => (
                        <Grid key={current._id} item xs={12} sm={12} md={4} lg={2} xl={2}>
                            <CardModulo infos={current} nome={current.nome} descricao={current.descricao} imagem={current.imagem}/>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Box>
    );
}
