import React from "react";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Pagination, Drawer, TextField, Chip } from "@mui/material";
import { NavBarPainel } from "../../components/painel/NavBarPainel";
import { InfoPainel } from "../../components/painel/InfoPainel";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer } from "react-toastify";
import { DrawerCurso } from "../../components/painel/DrawerCurso";
import useFetchGetPainelCursos from "../../queries/painel/cursos";
import { useNavigate } from "react-router-dom";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PageviewIcon from '@mui/icons-material/Pageview';
import SwapVertIcon from '@mui/icons-material/SwapVert';

export const CursosPainel = () => {
  const [dataEdit, setDataEdit] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate();

  const handleChange = (event, value) => {
    setPage(value);
  }
  
  const handleDrawer = () => {
    setOpen(true);
  }

  const { isLoading, data } = useFetchGetPainelCursos(page)

  const OpenLinkInNewTab = (link) => {
    window.open(link, '_blank');
    };

    function handleModulo(data) {
      navigate("/painel/modulos", { state: data });
    }

    function handleEdit(current) {
      setDataEdit(current)
      setOpen(true);
    }

  return (
    <Box>
      <NavBarPainel />
      <InfoPainel paginaAnterior="DTR Academy" paginaAtual="Cursos" />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 8 }}
      >
        <Button onClick={handleDrawer} variant="contained" startIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Stack>
      <Box sx={{ mt: 2, px: 8 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Cód. produto</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Ordem</TableCell>
                <TableCell>Imagem</TableCell>
                <TableCell>Compra</TableCell>
                <TableCell>quant. módulos</TableCell>
                <TableCell>quant. aulas</TableCell>
                <TableCell>tipo</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data.map(current => (
                <TableRow key={current._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>
                  {current.product_cod}
                </TableCell>
                <TableCell>
                  {current.nome}
                </TableCell>
                <TableCell><Chip size="small" icon={<SwapVertIcon />} label={current.ordem} /></TableCell>
                <TableCell><Button endIcon={<OpenInNewIcon />} onClick={() => OpenLinkInNewTab(current.imagem)} size="small" variant="text" >VER</Button></TableCell>
                <TableCell><Button endIcon={<OpenInNewIcon />} onClick={() => OpenLinkInNewTab(current.linkCompra)} size="small" variant="text" >VER</Button></TableCell>
                <TableCell>{current.quantModulos}</TableCell>
                <TableCell>{current.quantaulas}</TableCell>
                <TableCell>{current.isPublished ? <Chip sx={{px: 1}} icon={<CloudDoneIcon fontSize="small"/>}  size="small" color='success' label="Publicado" variant="outlined" /> : <Chip sx={{px: 0.5}} icon={<HourglassBottomIcon fontSize="small"/>}  size="small" color='warning' label="Lançamento" variant="outlined" />}</TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Button endIcon={<PageviewIcon/>} size="small" variant="contained" onClick={() => handleModulo(current)} >Módulos</Button>
                    <Button onClick={() => handleEdit(current)} size="small" variant="outlined" color='warning' >Editar</Button>
                  </Stack>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <DrawerCurso open={open} setOpen={setOpen} setDataEdit={setDataEdit} dataEdit={dataEdit}/>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};
