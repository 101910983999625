import { Box, Typography} from "@mui/material";

export const Title = ({children}) => {
  return (
    <Box sx={{mb: 2}}>
          <Typography sx={{
            fontSize: "1.5rem",
            color: "#e1e1e6",
            lineHeight: "125%",
            margin: "0px",
            fontWeight: "bold"
          }}>
          {children}
          </Typography>
        </Box>
  );
}
