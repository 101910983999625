import React from "react";
import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Backdrop, CircularProgress, Pagination, Drawer, TextField, Chip } from "@mui/material";
import { NavBarPainel } from "../../components/painel/NavBarPainel";
import { InfoPainel } from "../../components/painel/InfoPainel";
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import useFetchGetPainelAlunos from "../../queries/painel/alunos";
import ReplayIcon from '@mui/icons-material/Replay';
import BlockIcon from '@mui/icons-material/Block';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { DrawerAlunos } from "../../components/painel/DrawerAlunos";
import PageviewIcon from '@mui/icons-material/Pageview';
import usePlataforma from "../../hooks/usePlataforma";

export const AlunosPainel = () => {
  const [dataEdit, setDataEdit] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  let navigate = useNavigate();
  const { resetpassword, blockUser } = usePlataforma()

  const location = useLocation();
    const { state } = location;

  const handleChange = (event, value) => {
    setPage(value);
  }
  
  const handleDrawer = () => {
    setOpen(true);
  }

  const { isLoading, data } = useFetchGetPainelAlunos(page)
  
    function handleClick(data) {
      navigate("/painel/aluno/cursos", { state: data });
    }

function handleEdit(current) {
      setDataEdit(current)
      setOpen(true);
    }

    function alterarSenha(email) {
      toast.info("Foi enviado um e-mail de redefinição de senha.")
      resetpassword(email)
    } 

    function handleBlock(id, status) {
      toast.info(status ? "Aluno bloqueado." : "Aluno desbloqueado.")
      blockUser(id, status)
    } 

  return (
    <Box>
      <NavBarPainel />
      <InfoPainel paginaAnterior="Voltar" paginaAtual="Alunos" />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, px: 8 }}
      >
        <Button onClick={handleDrawer} variant="contained" startIcon={<AddIcon />}>
          Adicionar
        </Button>
      </Stack>
      <Box sx={{ mt: 2, px: 8 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell >Reenviar acesso</TableCell>
                <TableCell >Alterar Status</TableCell>
                <TableCell > </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.data.docs.map(current => (
                <TableRow key={current._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell >{current.isActive ? "Liberado" : "Bloqueado"}</TableCell>
                <TableCell component="th" scope="row">
                  {current.nome}
                </TableCell>
                <TableCell >{current.email}</TableCell>
                <TableCell ><Button onClick={() => alterarSenha(current.email)} startIcon={<ReplayIcon/>} size="small" variant="text" >Reenviar acesso</Button></TableCell>
                <TableCell >{current.isActive ? <Button onClick={() => handleBlock(current._id, current.isActive)} startIcon={<BlockIcon/>} color="error" size="small" variant="text" >Bloquear</Button>: <Button onClick={() => handleBlock(current._id, current.isActive)} startIcon={<TaskAltIcon/>} color="success" size="small" variant="text" >Liberar</Button>}</TableCell>
                <TableCell align="right">
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                  >
                    <Button endIcon={<PageviewIcon/>} size="small" variant="contained" onClick={() => handleClick(current)} >Cursos</Button>
                    <Button onClick={() => handleEdit(current)} size="small" variant="outlined" color='warning' >Editar</Button>
                  </Stack>
                </TableCell>
              </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mb: 3 }}
      >
       {data && <Pagination count={data ? data.totalPages : 1} page={page} onChange={handleChange} variant="outlined" shape="rounded" color='primary' />} 
      </Stack>
      <DrawerAlunos open={open} setOpen={setOpen} setDataEdit={setDataEdit} dataEdit={dataEdit}/>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
    </Box>
  );
};
