import { useQuery } from "react-query";
import api from "../../service/api";

async function getFavoritas() {
  const { data } = await api.get('/favoritas')
  return data
}

export default function useFetchGetFavoritas() {
    return useQuery(['favoritas'], getFavoritas, { refetchOnWindowFocus: false})
}