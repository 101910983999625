import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelModulos(ctx) {
  const [, page, cursoID] = ctx.queryKey
  const { data } = await api.get('/painel/modulos', { params: { page, cursoID } })
  return data
}

export default function useFetchGetPainelModulos(page, cursoID) {
    return useQuery(['painelmodulos', page,cursoID ], getPainelModulos, { refetchOnWindowFocus: false})
}