import { useQuery } from "react-query";
import api from "../../service/api";

async function getCursos() {
  const { data } = await api.get('/cursos')
  return data
}

export default function useFetchGetCursos() {
    return useQuery(['cursos'], getCursos, { refetchOnWindowFocus: false})
}