import api from "../service/api"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query";

const usePlataforma = () => {
    const queryClient = useQueryClient();

    const marcaAsstida = async (aulaID) => {
        try {
            await api.post("/aula/assistida", { aulaID })
            queryClient.invalidateQueries(['aulas'])
        } catch (error) {
            console.log(error)
        }
    }

    const marcaFavorita = async (aulaID) => {
        try {
            await api.post("/aula/favorita", { aulaID })
            queryClient.invalidateQueries(['aulas'])
        } catch (error) {
            console.log(error)
        }
    }

    const removerFavorita = async (aulaID) => {
        try {
            await api.delete(`/aula/favorita/${aulaID}`)
            queryClient.invalidateQueries(['aulas'])
        } catch (error) {
            console.log(error)
        }
    }

    const removerAssistida = async (aulaID) => {
        try {
            await api.delete(`/aula/assistida/${aulaID}`)
            queryClient.invalidateQueries(['aulas'])
        } catch (error) {
            console.log(error)
        }
    }

    const blockUser = async (id, status) => {
        try {
            await api.post(`/block/user/${id}`, { isActive: !status })
            queryClient.invalidateQueries(['painelalunos'])
        } catch (error) {
            console.log(error)
        }
    }

    const apagarMatricula = async (id) => {
        try {
            await api.post(`/apagar/matricula/${id}`)
            queryClient.invalidateQueries(['painelmatriculas'])
        } catch (error) {
            console.log(error)
        }
    }

    const resetpassword = async (email) => {
        try {
            await api.post("resetpassword", { email })
        } catch (error) {
            console.log(error)
        }
    }


    
    return {
        marcaAsstida,
        marcaFavorita,
        removerFavorita,
        removerAssistida,
        resetpassword,
        blockUser,
        apagarMatricula
    };
}

export default usePlataforma;
