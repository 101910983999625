import { Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField} from "@mui/material";
import { Title } from "../Title";
import { toast } from "react-toastify";
import api from "../../service/api";
import { useEffect, useState } from "react";
import { Input } from "./Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQueryClient } from "react-query";

const drawerWidth = 300;

export const DrawerAlunos = ({ open, setOpen, dataEdit, setDataEdit }) => {
  const queryClient = useQueryClient();
  const [isNew, setIsNew] = useState(true);
  const [idModulo, setIdModulo] = useState(null);

  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
    },
    validationSchema: Yup.object({
      nome: Yup.string().required('Esse campo é obrigatório!'),
    }),
    onSubmit: async (values) => {
      const id = toast.loading("Carregando...");

      const data = new FormData();
      data.append("nome", values.nome);
      data.append("email", values.email);

      const options = {
        method: "POST",
        url: isNew ? "/auth/user" : `/edit/user/${idModulo}`,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=---011000010111000001101001",
        },
        data,
      };

      api.request(options)
        .then(function (response) {
          toast.update(id, {
            render: "Registro salvo com sucesso.",
            type: "success",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
          queryClient.invalidateQueries(['painelalunos'])
          closeDrawer()
        })
        .catch(function (error) {
          toast.update(id, {
            render: error.response.data.message,
            type: "error",
            isLoading: false,
            autoClose: true,
            closeButton: true,
          });
        });
    },
  });

  useEffect(() => {
    if (dataEdit) {
      setIsNew(false)
      setIdModulo(dataEdit._id)
      formik.setValues({
        nome: dataEdit.nome,
        email: dataEdit.email,
      });
    }
  }, [dataEdit]);

  function closeDrawer() {
    setIdModulo(null)
    setIsNew(true)
    formik.resetForm();
    setOpen(false)
    setDataEdit(null)
  }

    return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <Box sx={{height: 73, bgcolor: "#323238", mb: 3}}>
          <Box sx={{mt: 3, ml: 3}}>
          <Title>{isNew ? "Adicionar Aluno" : "Editar Aluno"}</Title>
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit}>
        <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        sx={{px: 2}}
      >
        <Input formik={formik} id="nome" label="Nome" />
        <Input formik={formik} id="email" label="Email" type="email" />
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
        sx={{ my: 2, px: 2}}
      >
        <Button type="submit" disabled={formik.isSubmitting} fullWidth size="small" variant="contained" >{isNew ? "Salvar" : "Editar"}</Button>
        <Button onClick={closeDrawer} fullWidth size="small" variant="outlined" color='warning' >Cancelar</Button>
      </Stack>
      </form>
      </Drawer>
  );
}
