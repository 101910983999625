import { Box, Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import usePlataforma from "../hooks/usePlataforma";
import { useEffect, useState } from "react";

export const ButtonFavorita = ({ nome, marked, aulaID }) => {
  const [ativo, setAtivo] = useState(marked);
  const { marcaFavorita, removerFavorita} = usePlataforma()
  

  useEffect(() => {
    setAtivo(marked);
  }, [marked]);


  function handleMarked() {
    if(ativo) {
      setAtivo(false)
      removerFavorita(aulaID)
    } else {
      setAtivo(true)
      marcaFavorita(aulaID)
    }
  }

  return (
    <Button onClick={handleMarked} fullWidth variant="contained" color={ativo ? "primary" : "btn"} startIcon={ativo ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}>
      {nome}
    </Button>
  );
};
