import { Avatar, Backdrop, Box, Card, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import { NavBar } from "../components/NavBar";
import { InfoModulo } from "../components/InfoModulo";
import VideoPlayer from "../components/VideoPlayer";
import { AdjustScreen } from "../components/AdjustScreen";
import { ListPlayList } from "../components/ListPlayList";
import PerfectScrollbar from 'react-perfect-scrollbar';
import "react-perfect-scrollbar/dist/css/styles.css";
import TabDescricao from "../components/TabDescricao";
import { useLocation } from "react-router-dom";
import useFetchGetAulas from "../queries/aulas";
import { useEffect, useState } from "react";
import config from "../constants";
import { ButtonAssistida } from "../components/ButtonAssistida";
import { ButtonFavorita } from "../components/ButtonFavorita";

export const AulasFavoritas = () => {
    const location = useLocation();
    const { state } = location;
    const [curso, setCurso] = useState(state.atual);
    
    return (
        <Box>
            <NavBar />
            <InfoModulo paginaAtual="PlayList Favoritas" paginaAnterior="Aulas Favoritas" />
            <AdjustScreen>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}  >
                      {state && <VideoPlayer aulaID={curso.aulaID._id} src={curso.aulaID.video} />}  
                        <Typography sx={{mt: 1, fontSize: "1.3rem", color: "#E1E1E6", fontWeight: 700}} color="initial">{curso ? curso.nome : ""}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Card sx={{ bgcolor: "#19191C", minHeight: 410 }} >
                            <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%', bgcolor: "#323238", maxHeight: 48 }}
                            >
                                <Box sx={{py: 1}}>
                            <Typography sx={{fontSize: "1.1rem", color: "#E1E1E6", fontWeight: 700}} color="initial">Playlist Favoritas</Typography>
                                </Box>
                            </Stack>
                            <PerfectScrollbar style={{ maxHeight: 311 }}>
                            <List>
                                {state && state.aulas.map(current => (
                                <ListPlayList
                                curso={current}
                                setCurso={setCurso}
                                key={current._id}
                                imagem={current.aulaID.imagem}
                                nomeAula={current.aulaID.nome}
                                selected={current.aulaID._id === curso.aulaID._id}
                                />
                                ))}
                            </List>
                            </PerfectScrollbar>
                        </Card>
                    </Grid>
                </Grid>
            </AdjustScreen>
            <Box sx={{bgcolor: "rgb(18, 18, 20)", mt: 7}}>
                <AdjustScreen>
                    <Box sx={{pb: 3}}/>
                    <TabDescricao descricao={curso ? curso.aulaID.descricao : ""} aulaID={curso ? curso.aulaID._id : ""}/>
                </AdjustScreen>
                <Box sx={{pb: 4}}/>
            </Box>
        </Box>
    );
}
