import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Box, Button, IconButton, Stack } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import useStore from '../providers/useStore';
import { getInitials } from '../utils/get-initials';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import config from '../constants';

export const NavBar = () => {
  const user = useStore((state) => state.user)
  const avatarUser = user ? user.imagem : null
  const setToken = useStore((state) => state.setToken)
  var nome = getInitials(user ? user.nome : "")
  
  let navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };


  function handleLick(link) {
    navigate(link);
    handleClose()
  }


  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      sx={{
        height: 72,
        background: "#202024",
        borderBottom: 1.5, borderColor: "#323238",
        width: "100%"
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
      >
        <Box sx={{ width: 70, ml: 5 }}>
          <img src="/logo.png"
            alt="logo"
            style={{ width: '100%', height: '100%' }}
          />
        </Box>

        <IconButton id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="large"
        sx={{ color: "rgb(115, 115, 128)" }}>
         {open ? <CloseIcon fontSize="inherit" /> : <MenuIcon fontSize="inherit" />} 
        </IconButton>
        <Menu
        sx={{ boxShadow: 'rgba(0, 0, 0, 0.6) 0px 5px 20px' }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button' 
        }}
      >
        <Box sx={{px: 2}}>
        <MenuItem sx={{
        borderLeft: location.pathname === "/" ? '2px solid #00B37E' : 'none',
      }} onClick={() => handleLick("/")}>Início</MenuItem>
        <MenuItem sx={{
        borderLeft: location.pathname === "/favoritos" ? '2px solid #00B37E' : 'none',
      }} onClick={() => handleLick("/favoritos")}>Favoritos</MenuItem>
        <MenuItem sx={{
        borderLeft: location.pathname === "/comunidade" ? '2px solid #00B37E' : 'none',
      }} onClick={() => handleLick("/comunidade")}>Comunidade</MenuItem>
        </Box>
      </Menu>
      </Stack>
      <Box sx={{ pr: 4 }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={0}
      >
        <Avatar src={avatarUser} onClick={() => handleLick("/perfil")} sx={{
          border: 2,
          borderColor: "rgb(115, 115, 128)",
          cursor: "pointer",
        }}>{nome}</Avatar>
        <IconButton  id="basic-button2"
        aria-controls={open2 ? 'basic-menu2' : undefined}
        aria-haspopup="true"
        aria-expanded={open2 ? 'true' : undefined}
        onClick={handleClick2}
        size="large" sx={{ color: "rgb(115, 115, 128)" }} aria-label="menu">
          <ExpandMoreIcon />
        </IconButton>
      </Stack>
      <Menu
        sx={{ boxShadow: 'rgba(0, 0, 0, 0.6) 0px 5px 20px' }}
        id="basic-menu"
        anchorEl={anchorE2}
        open={open2}
        onClose={handleClose2}
        MenuListProps={{
          'aria-labelledby': 'basic-button2' 
        }}
      >
        <MenuItem>
        <Button onClick={() => handleLick("/perfil")} fullWidth variant="outlined" startIcon={<PersonIcon />}>
          Perfil
        </Button>
        </MenuItem>
        <MenuItem>
        <Button onClick={() => setToken(null)} fullWidth variant="outlined" startIcon={<ExitToAppIcon />}>
          Sair
        </Button>
        </MenuItem>
      </Menu>
      </Box>
    </Stack>
  );
}
