import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelAlunos(ctx) {
  const [, page] = ctx.queryKey
  const { data } = await api.get('/painel/alunos', { params: { page } })
  return data
}

export default function useFetchGetPainelAlunos(page) {
    return useQuery(['painelalunos', page], getPainelAlunos, { refetchOnWindowFocus: false})
}