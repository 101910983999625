import { Box, Card, CardHeader, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const CardModulo = ({imagem, nome, descricao, infos}) => {
  let navigate = useNavigate();

  function handleAulas() {
    navigate("/aulas", { state: infos });
  }


  return (
    <Box sx={{ p: 1 }}>
      <Card
      onClick={handleAulas}
        sx={{
          maxWidth: 200,
          border: 1,
          borderColor: "#323238",
          transition: "all .2s ease-in-out",
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.03)",
          },
        }}
      >
        <CardMedia
          sx={{
            width: "100%",
            height: 380,
          }}
          image={imagem}
          // title="m1"
        />
      </Card>
      <CardHeader
        sx={{ maxWidth: 200 }}
        titleTypographyProps={{
          style: { fontSize: "1.2rem", color: "#E1E1E6" },
        }}
        subheaderTypographyProps={{
          style: { fontSize: "0.7rem", color: "#C4C4CC" },
        }}
        title={nome}
        subheader={descricao}
      />
    </Box>
  );
};
