import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelMatriculas(ctx) {
  const [, page, userID] = ctx.queryKey
  const { data } = await api.get('/painel/matriculas', { params: { page, userID } })
  return data
}

export default function useFetchGetPainelMatriculas(page, userID) {
    return useQuery(['painelmatriculas', page,userID ], getPainelMatriculas, { refetchOnWindowFocus: false})
}