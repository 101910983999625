import { useQuery } from "react-query";
import api from "../../../service/api";

async function getPainelAulas(ctx) {
  const [, page, moduloID] = ctx.queryKey
  const { data } = await api.get('/painel/aulas', { params: { page, moduloID } })
  return data
}

export default function useFetchGetPainelAulas(page, moduloID) {
    return useQuery(['painelaulas', page,moduloID ], getPainelAulas, { refetchOnWindowFocus: false})
}