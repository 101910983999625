
import * as React from 'react';
import { Alert, Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../service/api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useStore from '../providers/useStore';

export const Resetpassword = ({}) => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userID = searchParams.get('userID');
  const resetPassword = searchParams.get('resetPassword');
  const [erroQuery, seterroQuery] = React.useState(false);


  function handleLogin() {
    let executed = false;
    setTimeout(function() {
      if (!executed) {
        navigate("/login", { replace: true });
        executed = true;
      }
    }, 4000);
  }

  React.useEffect(() => {
    if (!userID || !resetPassword) {
      seterroQuery(true)
    }
  }, [userID, resetPassword]);


  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmaSenha: ''
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('A nova senha é obrigatória'),
      confirmaSenha: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Confirmação de senha não confere")
        .required("Confirmação de senha é obrigatório")
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      const id = toast.loading("Carregando...");
      try {
        const { data } = await api.post("/newpassword", {...values, userID, resetPassword});
        setIsLoading(false)
        toast.update(id, {
          render: "Senha redefinida com sucesso.",
          type: "success",
          isLoading: false,
          autoClose: true,
          closeButton: true,
        });
        handleLogin()
      } catch (error) {
        seterroQuery(true)
        setIsLoading(false)
        toast.update(id, {
          render: error.response.data.message,
          type: "error",
          isLoading: false,
          autoClose: true,
          closeButton: true,
        });
      }
    },
  });

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      backgroundImage: 'url(https://caiotapereading.com.br/static/backgroundImage.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <Box sx={{ textAlign: "center", maxWidth: 140 }}>
              <img
                alt="logo"
                src="/logo.png"
                style={{
                  display: "inline-block",
                  width: '100%',
                }}
              />
            </Box>
      <Paper elevation={2} sx={{mt: 2, px:5, py: 5, width: {xs: "90%", sm: "90%", md: 440, lg: 440, xl: 440}, borderRadius: 5 }} >
        <Box >
      <Typography color="text.primary" sx={{fontSize: 24, fontWeight: 600}}>
      Redefinir sua senha
      </Typography>
      <form onSubmit={formik.handleSubmit}>
      <Box mt={3}>
      <Typography color="text.primary"  sx={{fontSize: 14, fontWeight: 600}}>
      Nova senha
      </Typography>
      <TextField
      name="newPassword"
      value={formik.values.newPassword}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      size="small"
      sx={{mt: 0.5}}
      fullWidth
      variant="outlined"
      type='password'
      error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
      helperText={formik.touched.newPassword && formik.errors.newPassword}
      />
      </Box>
      <Box mt={3}>
      <Typography color="text.primary" sx={{fontSize: 14, fontWeight: 600}}>
      Confirme a senha
      </Typography>
      <TextField
      name="confirmaSenha"
      value={formik.values.confirmaSenha}
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      size="small"
      sx={{mt: 0.5}}
      fullWidth
      variant="outlined"
      type='password'
      error={Boolean(formik.touched.confirmaSenha && formik.errors.confirmaSenha)}
      helperText={formik.touched.confirmaSenha && formik.errors.confirmaSenha}
      />
      </Box>
      <Box mt={3}>
      <Button disabled={isLoading || erroQuery} type="submit" size="medium" fullWidth variant="contained">Redefinir</Button>
      </Box>
      </form>
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", mt: 2 }}>
        {erroQuery && <Alert severity="error">Desculpe, mas este link expirou. Por favor, tente solicitar uma redefinição novamente.</Alert>}
          </Box>
      </Paper>
      <ToastContainer />
    </Box>
  );
}
